@import "tailwindcss/utilities";

@layer utilities {
	.hyphens {
		hyphens: auto;
	}

	.break-avoid {
		overflow: hidden;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid-column;
	}

	/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.

	  @see https://github.com/wicg/focus-visible
	*/
	.js-focus-visible :focus:not(.focus-visible) {
		outline: none;
	}

	/*
	 * Clearfix: contain floats
	 *
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    `contenteditable` attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that receive the `clearfix` class.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
	.clearfix {
		&::before,
		&::after {
			content: " "; /* 1 */
			display: table; /* 2 */
		}

		&::after {
			clear: both;
		}
	}
}
